import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { Container, Grid, Text, Box, Heading } from "theme-ui"

export const Head = () => (
  <>
    <title>Terms and Conditions</title>
    <meta name="description" content="Terms" />
  </>
)

const Terms = () => {
  const data = useStaticQuery(graphql`
    query TermsQuery {
      craft {
        entries(enabledForSite: true, section: "termsAndConditions") {
          ... on CRAFT_termsAndConditions_termsAndConditions_Entry {
            body
          }
        }
      }
    }
  `)
  return (
    <Layout variant="sidebar">
      <Container variant="fluid" >
        <Grid gap={[2, 2, 3, 1]} row={[1]} columns={[1]} sx={{ backgroundColor: ['white', 'transparent'], display: 'relative', paddingBottom: '5em'}}>
        <Box sx={{
              overflow: "hidden",
              position: 'relative',
              display: 'block',
              width: '80%',
              margin: '2em auto'
            }}>
            <Heading as="h1" variant="h1" className='soft-faux-font'>
              Terms and Conditions
            </Heading>
            <Text
              variant="paragraph"
              dangerouslySetInnerHTML={{ __html: data.craft.entries[0].body }}
            />
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Terms